import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Insights from "../../molecules/Insights";

// Components
import StaffingHeader from "../../molecules/staffing/staffingHeader";
import StaffingDescription from "../../molecules/staffing/staffingDescription";
import StaffingTechnicalCapabilities from "../../molecules/staffing/staffingTechnicalCapabilities";
import StaffingModelOfEngagement from "../../molecules/staffing/staffingModelOfEngagement";
// import ContactForm from "../../molecules/ContactForm";
import ImageBanner from "../../components/atoms/banner/imageBanner";
import { useStaticQuery, graphql } from "gatsby";

//images
import bannerImg from "../../images/servicesassets/automation.png";
import descImg from "../../images/servicesassets/automation-description.jpg";
import StaffingNewTimelineComponent from "../../molecules/staffing/staffingNewTimelineComponent";
import HeaderWithVideo from "../../molecules/HeaderWithVideo";

//video
// import AutomationVideo from "../../videos/AutomationVideo.mp4";
import GokenGoDescription from "../../molecules/gokengo/GokenGoDescription";
import HalfImageDescription from "../../molecules/Common/HalfImageDescription";
import needForAutomationImage from "../../images/servicesassets/automation/need_automation.png";
import typesAutomationImage from "../../images/servicesassets/automation/types_automation.png";
const Automation = () => {
  const data = useStaticQuery(graphql`
    query GetAutomationData {
      gcms {
        servicePages(where: { pageType: AUTOMATION }) {
          heading
          headerImage {
            url
          }
          headerVideo {
            url
          }
          paragraph
          description
          timelineItems {
            heading
            paragraph
            listItem
            isList
          }
          technicalItems {
            heading
            paragraph
            icons {
              url
            }
          }
          sliderModals {
            tagline
            heading
            benefit
          }
          insightPages {
            shortDescription
            title
            slug
            image {
              url
            }
          }
        }
      }
    }
  `);

  return (
    <Layout isNavWhite={true} hideMap hideFAQ>
      <SEO
        canonicalLink={"https://www.goken-global.com/services/automation/"}
        title={"Automation Services: Automation Solutions by Goken"}
        description={
          "Embrace the future with Goken's cutting-edge automation services. Boost efficiency and innovation in your business. Click to discover our solutions!"
        }
        // meta="Automation, KBE, knowledge based engineering, C++, Automation Services, RPA, automation studio, RPA Automation, automation technology, automation engineering, robotic process automation, PLM, product lifecycle management, PLM infrastructure"
        facebookTitle={
          "Goken - Engineering Design Automation in USA & India | Robotic Process Automation & More"
        }
        facebookDescription={
          "Goken Engineering Design Automation in USA and India provides soultions to complex design situations. CAD customization, knowledge-based engineering, or Robotic Process Automation helps to minimise errors and saves time of designer. "
        }
        keywords={
          "engineering design automation, robotic process automation, CAD customization, knowledge based engineering, efficiency"
        }
      />
      <HeaderWithVideo
        headingOne={data.gcms.servicePages[0].heading}
        para={data.gcms.servicePages[0].paragraph}
        btnTxt="Get In Touch"
        url={"/contactus/"}
        video={data.gcms.servicePages[0].headerVideo}
      />
      {/* <StaffingHeader
        heading={data.gcms.servicePages[0].heading}
        paragraph={data.gcms.servicePages[0].paragraph}
        image={bannerImg}
        btnTxt="Get In Touch"
      ></StaffingHeader> */}
      {/* <GokenGoDescription
        headingOne="NEED FOR AUTOMATION"
        description={data.gcms.servicePages[0].description}
        image={descImg}
        url="/contactus/"
      /> */}
      <HalfImageDescription
        heading="NEED FOR AUTOMATION"
        paragraph={data.gcms.servicePages[0].description}
        image={needForAutomationImage}
        reverserImage={true}
        extraContentClasses={"content-left-aligned"}
        extraImageClasses={"smaller-image-desktop"}
        extraImageDivClasses={"automation-image-wrapper"}
        grayBackground={true}
      />

      <StaffingNewTimelineComponent
        heading="OUR PROCESS"
        ctaFirst={true}
        style={"yes"}
        bannerService={"Automation"}
        tagline="Goken uses a methodical approach from discovery to development to deployment"
        items={data.gcms.servicePages[0].timelineItems}
      />
      <StaffingTechnicalCapabilities
        heading="Technical Capabilities"
        paragraph="We use the following automation tools to develop templates, macros and applications"
        listItems={data.gcms.servicePages[0].technicalItems}
      ></StaffingTechnicalCapabilities>
      <StaffingModelOfEngagement
        heading="Types of Automation"
        tagline="Goken uses a holistic approach toward digital product management. By combining our design management and automation capabilities, we have been able to improve productivity of our clients by as much as 30%. Goken provides the following services to our clients:"
        modals={data.gcms.servicePages[0].sliderModals}
        withImageMode={true}
        leftImage={typesAutomationImage}
      ></StaffingModelOfEngagement>
      <div className="w-90-wrapper">
        <Insights
          insights={data.gcms.servicePages[0].insightPages}
          showTitle={true}
        ></Insights>
      </div>
      {/* <ContactForm
        showForm={true}
        typeOfForm="Product or Service Inquiry"
      ></ContactForm> */}
      <ImageBanner
        heading="Learn more about Automation"
        btnText="Get in Touch"
        link="/contactus/"
      ></ImageBanner>
    </Layout>
  );
};

export default Automation;
